.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.main-content {
  padding-left: 72px;
  padding-top: 64px;
  margin-right: 0;
  transition: margin 0.3s;
}

.main-content.menu-opened {
  padding-left: 200px;
}
